import React, { memo, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { graphql } from 'gatsby';
import { useCSSMotion, useSectionTimeSpent, customPropTypes, useCTAClickTracking, sanitizer } from '../../util';
import { CTALink } from '../CTA';

import './MissionStatement.scss';

function MissionStatement({
  data: {
    subtitle,
    title,
    description,
    name,
    id,
    descriptionWidth,
    ctaLink,
    darkBackground = false,
    titleLevel = 'h2',
    titleColor = 'purple',
  },
}) {
  const containerRef = useRef(null);
  const motionState = useCSSMotion(containerRef);

  useSectionTimeSpent(name, containerRef);

  const handleCtaClick = useCTAClickTracking(name);

  const Heading = titleLevel;

  return (
    <div
      id={id}
      className={classnames('MissionStatement', motionState, descriptionWidth, { darkBackground })}
      ref={containerRef}
    >
      <p className="sub-title">{subtitle}</p>
      {title && <Heading className={classnames('title', titleColor)}>{title}</Heading>}
      <div className="blog-style description" dangerouslySetInnerHTML={{ __html: sanitizer(description) }} />
      {ctaLink?.url && (
        <CTALink
          onClick={handleCtaClick}
          link={ctaLink.url}
          text={ctaLink.title}
          ariatext={ctaLink.title}
          white={darkBackground}
          motionState={motionState}
        />
      )}
    </div>
  );
}

MissionStatement.propTypes = checkExtraProps({
  data: PropTypes.shape({
    subtitle: PropTypes.string,
    ...customPropTypes.basePropTypes,
    title: PropTypes.string,
    titleLevel: PropTypes.oneOf(['h1', 'h2']),
    titleColor: PropTypes.oneOf(['purple', 'black']),
    description: PropTypes.string,
    ctaLink: customPropTypes.link,
    darkBackground: PropTypes.bool,
  }).isRequired,
});

MissionStatement.defaultProps = {};

export default memo(MissionStatement);

export const fragments = graphql`
  fragment MissionStatement on WpPage_Flexiblelayout_FlexibleChildren_MissionStatement {
    description
    id
    jumpToLinkTitle
    name
    subtitle
    title
    titleLevel
    titleColor
    darkBackground
    ctaLink {
      url
      title
      target
    }
  }

  fragment MissionStatement_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_MissionStatement {
    description
    id
    jumpToLinkTitle
    name
    subtitle
    title
    titleLevel
    titleColor
    darkBackground
    ctaLink {
      url
      title
      target
    }
  }
`;
